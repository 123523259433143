import React from 'react';
import { StaticImageData } from 'next/image';
import Slider from 'react-slick';
import { useMemo } from 'react';
import useWindowSize from '@/hooks/useWindowSize';

import ImageComponent from '@/components/UI/image';

import style from './slider.module.scss';

const EmptyNextArrow = () => null;
const EmptyPrevArrow = () => null;

type Props = {
  listSlider: StaticImageData[];
};

const SliderPartner: React.FC<Props> = ({ listSlider }) => {
  const { windowWidth } = useWindowSize();

  const settings = useMemo(() => {
    let sliderSettings = {
      dots: false,
      Infinity: true,
      speed: 800,
      slidesToShow: 7,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      cssEase: 'linear',
      adaptiveHeight: true,
      draggable: false,
      nextArrow: <EmptyNextArrow />,
      prevArrow: <EmptyPrevArrow />,
    };

    switch (true) {
      case windowWidth <= 320:
        sliderSettings.slidesToShow = 3;
        break;
      case windowWidth <= 480:
        sliderSettings.slidesToShow = 4;
        break;
      case windowWidth <= 512:
        sliderSettings.slidesToShow = 5;
        break;
      case windowWidth <= 768:
        sliderSettings.slidesToShow = 6;
        break;
      case windowWidth <= 980:
        sliderSettings.slidesToShow = 4;
        break;
      case windowWidth <= 1100:
        sliderSettings.slidesToShow = 5;
        break;
      case windowWidth <= 1470:
        sliderSettings.slidesToShow = 6;
        break;
      default:
        sliderSettings.slidesToShow = 7;
        break;
    }

    return sliderSettings;
  }, [windowWidth]);

  const renderSlider = useMemo(() => {
    return (
      <Slider {...settings}>
        {listSlider.map((slider, index) => (
          <div key={index} className={style.slider}>
            <ImageComponent image={slider} alt={'icon'} />
          </div>
        ))}
      </Slider>
    );
  }, [listSlider, settings]);

  return renderSlider;
};

export default SliderPartner;
