import Slider from './slider';

import style from './partners.module.scss';
import { useMemo } from 'react';
import useWindowSize from '@/hooks/useWindowSize';
import { useTranslation } from 'react-i18next';
import { importPartnersImage } from '@/helper/functions';

const Partners = () => {
  const { windowWidth } = useWindowSize();

  const renderSlider = useMemo(() => {
    const listSlidersBig = [
      importPartnersImage('Bitter'),
      importPartnersImage('ChinChin'),
      importPartnersImage('Cool'),
      importPartnersImage('Elephant'),
      importPartnersImage('G'),
      importPartnersImage('IQPizza'),
      importPartnersImage('Libkin'),
      importPartnersImage('Merci'),
      importPartnersImage('Pomodoro'),
      importPartnersImage('PizzaDAY'),
      importPartnersImage('VarDelivery'),
    ];
    const listSlidersSmall = [
      importPartnersImage('Bitter_small'),
      importPartnersImage('ChinChin_small'),
      importPartnersImage('Cool_small'),
      importPartnersImage('Elephant_small'),
      importPartnersImage('G_small'),
      importPartnersImage('IQPizza_small'),
      importPartnersImage('Libkin_small'),
      importPartnersImage('Merci_small'),
      importPartnersImage('Pomodoro_small'),
      importPartnersImage('PizzaDay_small'),
      importPartnersImage('VarDelivery_small'),
    ];
    if (windowWidth > 768) {
      return <Slider listSlider={listSlidersBig} />;
    }
    return <Slider listSlider={listSlidersSmall} />;
  }, [windowWidth]);
  const { t } = useTranslation();

  return (
    <section className={style.partners}>
      <div className={style.partners_title}>
        <span className={style.partners_title__top}>{t('slider.changed')}</span>
        <span className={style.partners_title__bottom}>{t('slider.join')}</span>
      </div>
      <div className={style.wrapperSlider}>{renderSlider}</div>
    </section>
  );
};

export default Partners;
